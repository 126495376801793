import * as R from 'ramda';
import { httpGet, httpPost } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';

const saveProblemHistory = async ({ content }) => R.pipe(
  adapter.problems.saveProblemHistory.fe2be,
  httpPost('problems/problemHistory'),
  response => response.then(adapter.problems.saveProblemHistory.be2fe),
)(content);

const getProblemHistory = async ({ params: { queries } }) => {
  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`problems/problemHistory${queriesString}`),
    response => response.then(adapter.problems.getProblemHistory.be2fe),
  )(queries);
};

const DM = {
    saveProblemHistory,
    getProblemHistory,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
