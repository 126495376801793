import * as R from 'ramda';

const createNotes = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const closePastAlert = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const closePaused = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const closeAlert = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveNotes = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const markNoteAsError = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  createNotes,
  closePastAlert,
  closePaused,
  closeAlert,
  retrieveNotes,
  markNoteAsError
};
