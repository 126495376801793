import * as R from 'ramda';

const fetchReporting = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchReportPatinetInfo = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchReportIndividualEnrollProgram = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAlertsSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getNumberOfAlertsPerProgramme = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAlertsDataChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getCareTaskAdherenceSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getCareTaskListProgrammes = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getCareTaskDataChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getVitalsSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getVitalDataChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getVitalsPerProgramme = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getLatestOTT = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const createIndividualReporting = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const retrieveReports = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const updateReport = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchIndividualReportTemplate = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchReportingTemplate = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchAllParticipantList = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const fetchOverallParticipantEP = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantVitalSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantVitalData = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantCareTaskSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantCareTaskChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantCareTaskData = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantAlertSummary = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParticipantAlertChart = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const getAllParicipantAlertData = {
    fe2be: R.identity,
    be2fe: R.identity,
};

const retrieveDailyParticipantsAlertsSummary = {
    fe2be: R.identity,
    be2fe: ({ value }) => value,
};

const retrieveDailyParticipantsPausedSummary = {
    fe2be: R.identity,
    be2fe: ({ value }) => value,
};

export default {
    fetchReporting,
    fetchReportPatinetInfo,
    fetchReportIndividualEnrollProgram,
    getAlertsSummary,
    getNumberOfAlertsPerProgramme,
    getAlertsDataChart,
    getCareTaskAdherenceSummary,
    getCareTaskListProgrammes,
    getCareTaskDataChart,
    getVitalsSummary,
    getVitalDataChart,
    getVitalsPerProgramme,
    getLatestOTT,
    createIndividualReporting,
    retrieveReports,
    updateReport,
    fetchIndividualReportTemplate,
    fetchReportingTemplate,
    fetchAllParticipantList,
    fetchOverallParticipantEP,
    getAllParticipantVitalSummary,
    getAllParticipantChart,
    getAllParticipantVitalData,
    getAllParticipantCareTaskSummary,
    getAllParticipantCareTaskChart,
    getAllParticipantCareTaskData,
    getAllParticipantAlertSummary,
    getAllParticipantAlertChart,
    getAllParicipantAlertData,
    retrieveDailyParticipantsAlertsSummary,
    retrieveDailyParticipantsPausedSummary
};