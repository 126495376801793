/* eslint-disable */
import * as R from 'ramda';
import moment from 'moment';

const create = {
  fe2be: ({
    avatar,
    orgInfo: {
      orgType: { value: type },
      parentOrg: { options: parentOrgOptions, value: parentOrgValue } = {},
      orgName: name,
      compRegNo: regNo,
      compPhone: phone,
      compAddr1: line_1,
      compAddr2: line_2,
      postalCode,
      country,
      state: { value: state = null } = {},
      city: { value: city },
    },
    accounts = [],
    billingInfo: {
      taxRegNo: taxRegistrationNumber,
      poNo: poNumber,
      billingDayth: { value: billingDate },
      paymentTerms,
      lateDeviceReturn,
      latePayment,
      paymentFees,
      refundDate: { value: refundDate },
      isInvoiceEnabled,
      isCovidOrg,
      isKioskOrg,
      isReportingEnabled,
    },
    addOnCovidOrganisation: {
      pricePlan = {},
      tier = {},
    } = {},
    attributes: { isExtensionEnabled, isSuspensionEnabled, isContinuousMonitoringEnabled },
    others: { sendMaintenanceNotification = {} } = {},
  }) => {
    const parentId =
      type === 'GROUP'
        ? R.pipe(R.find(R.propEq('value', R.prop('value', country))), R.prop('id'))(R.prop('options', country))
        : R.pipe(R.find(R.propEq('value', parentOrgValue)), R.prop('id'))(parentOrgOptions);
    const logo = {
      file_suffix: '.jpeg',
      data: R.replace('data:image/jpeg;base64,', '', avatar),
    };

    return {
      orgInfo: {
        type,
        parentId,
        name,
        logo,
        regNo,
        isTaxRegistered: !!taxRegistrationNumber,
        taxRegistrationNumber,
        isRequirePONumber: !!poNumber,
        poNumber,
        billingDate,
        refundDate,
        paymentTerm: parseFloat(paymentTerms),
        latePaymentDays: parseFloat(latePayment),
        lateDeviceReturnDays: parseFloat(lateDeviceReturn),
        latePaymentInterest: parseFloat(paymentFees),
        isInvoiceEnabled,
        isCovidOrg,
        pricePlan: pricePlan ? pricePlan.value : null,
        covidOrgTier: tier ? tier.value : null,
        isKioskOrg,
        isReportingEnabled,
        sendMaintenanceNotification: type === 'GROUP' && sendMaintenanceNotification ? true : null,
      },
      phone,
      address: [
        {
          type: 'BILLING',
          country: R.prop('value', country),
          state,
          city,
          line_1,
          line_2,
          postalCode,
        },
      ],
      contacts: accounts,
      ...(type !== 'CLUSTER')
      ? {
          attributes: {
            isExtensionEnabled,
            isSuspensionEnabled,
            isContinuousMonitoringEnabled
          },
        }
      : {}
    };
  },
  be2fe: R.identity,
};

const createCovid = {
  fe2be: ({
    avatar,
    basicInfo: {
      orgType: { value: type },
      parentOrg: { options: parentOrgOptions, value: parentOrgValue } = {},
      companyName: name,
      compRegNo: regNo,
      compPhone: phone,
      compAddr1: line_1,
      compAddr2: line_2,
      postalCode,
      country,
      state: { value: state = null } = {},
      city: { value: city },
    },
    contactAndLogin: {
      priFirstName,
      priLastName,
      priEmail,
      priMobile,
      priOffice,
      secFirstName,
      secLastName,
      secEmail,
      secMobile,
      secOffice,
    },
    paymentDetails: {
      noOfUsersMonitored: pricePlan,
      creditCardToken,
      billingAddress: { value: isSameBillingAddress },
      billingAddr1,
      billingAddr2,
      billingCountry,
      billingState: { value: billingState = null } = {},
      billingCity: { value: billingCity = null } = {},
      billingPostalCode,
    },
  }) => {
    // TO DO: parentId will not be found because not inside Organization table
    const parentId = R.pipe(
      R.find(R.propEq('value', R.prop('value', country))),
      R.prop('id'),
    )(R.prop('options', country));
    const logo = avatar
      ? {
          file_suffix: '.jpeg',
          data: R.replace('data:image/jpeg;base64,', '', avatar),
        }
      : {};

    // Prepare physical address & billing address
    const address = [
      {
        type: 'BILLING',
        country: isSameBillingAddress === 'same' ? R.prop('value', country) : R.prop('value', billingCountry),
        line_1: isSameBillingAddress === 'same' ? line_1 : billingAddr1,
        line_2: isSameBillingAddress === 'same' ? line_2 : billingAddr2,
        state: isSameBillingAddress === 'same' ? state : billingState,
        city: isSameBillingAddress === 'same' ? city : billingCity,
        postalCode: isSameBillingAddress === 'same' ? postalCode : billingPostalCode,
      },
    ].concat({
      type: 'SHIPPING',
      country: R.prop('value', country),
      state,
      city,
      line_1,
      line_2,
      postalCode,
    });

    const currentDate = new Date();
    const parsedDate = moment(currentDate).format('YYYY-MM-DD');
    const chargeItemCodes = [
      {
        skuName: 'BYOD Pulse Oximeter',
        code: 'HBSPO20-001-01',
        description: 'BYOD Oximeter',
        effectiveFrom: parsedDate,
      },
      {
        skuName: 'BYOD Thermometer',
        code: 'HBSTH20-001-01',
        description: 'BYOD Thermometer',
        effectiveFrom: parsedDate,
      },
    ];

    const MISSED_READING = 'MISSED_READING';
    const THRESHOLD_READING = 'THRESHOLD_READING';
    const BTM = 'btm';
    const PO = 'po';
    const messages = [
      {
        recipient: 'CARETEAM',
        type: ['EMAIL'],
        subject: 'Alert for Care Team',
      },
      {
        recipient: 'PATIENT',
        type: ['SMS'],
        subject: 'Alert for Participant',
      },
    ];

    const adminPrograms = [
      {
        skuName: 'BYOD Thermometer',
        issueDevice: false,
        title: 'COVID-19 Monitoring of Temperature',
        description:
          'Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2). For someone infected, the symptoms may appear 2-14 days after exposure and one of the symptoms is fever. The purpose of identifying individuals with fever is to allow individuals seek necessary medical attention and take action to stay at home or alternative measures to avoid contaminating others. Please note standard thresholds had been preset to WHO guidelines where Green is normal. At Orange, you should assess if you possess other symptoms of COVID-19 and obtain professional medical advice. At Red, kindly seek immediate medical attention.',
        category: ['COVID-19'],
        status: 'READY',
        duration: { length: 24, calendar: 'MONTH' },
        vitals: [
          {
            skuId: 0,
            vitalGroupName: 'BTM',
            thresholds: { btm: [35, 36.5, 38, 40] },
            schedule: {
              taskType: 'NON_BG',
              scheduleType: 'TIMESLOT',
              scheduleDetails: {
                timeslot: [
                  {
                    start: '0600',
                    end: '1200',
                  },
                  {
                    start: '1800',
                    end: '2359',
                  },
                ],
              },
            },
            notifications: [
              {
                alertType: MISSED_READING,
                consecutiveCount: 1,
                messages: messages,
                vitalDim: BTM,
              },
              {
                alertType: THRESHOLD_READING,
                consecutiveCount: 1,
                trigger: ['H'],
                messages: messages,
                vitalDim: BTM,
              },
              {
                alertType: THRESHOLD_READING,
                consecutiveCount: 1,
                trigger: ['HH'],
                messages: messages,
                vitalDim: BTM,
              },
            ],
            patientInstructions:
              'Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2). For someone infected, the symptoms may appear 2-14 days after exposure and one of the symptoms is fever. The purpose of identifying individuals with fever is to allow individuals seek necessary medical attention and take action to stay at home or alternative measures to avoid contaminating others. Please note standard thresholds had been preset to WHO guidelines where Green is normal. At Orange, you should assess if you possess other symptoms of COVID-19 and obtain professional medical advice. At Red, kindly seek immediate medical attention.',
          },
        ],
        devices: [{ skuId: 0, chargeItemCodeId: 0 }],
      },
      {
        skuName: 'BYOD Pulse Oximeter',
        issueDevice: false,
        title: 'COVID-19 Monitoring of Oxygen Saturation Levels',
        description:
          'Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2). Someone infected with COVID-19 and like other lung infections, level of oxygen saturation carried in red blood cells are reduced reflecting the reduced function of the lungs. The purpose of monitoring oxygen saturation level is to allow individuals feeling shortness of breadth or having cough monitor their condition and decide or to be decided by doctors if further actions is required. Please note standard thresholds had been preset to WHO guidelines where Green is normal. At Orange, you should assess if you possess other symptoms of COVID-19 and obtain professional medical advice. At Red, kindly seek immediate medical attention.',
        category: ['COVID-19'],
        status: 'READY',
        duration: { length: 24, calendar: 'MONTH' },
        vitals: [
          {
            skuId: 0,
            vitalGroupName: 'PO',
            thresholds: { po: [90, 94, 100, null] },
            schedule: {
              taskType: 'NON_BG',
              scheduleType: 'TIMESLOT',
              scheduleDetails: {
                timeslot: [
                  {
                    start: '0600',
                    end: '1200',
                  },
                  {
                    start: '1800',
                    end: '2359',
                  },
                ],
              },
            },
            notifications: [
              {
                alertType: MISSED_READING,
                consecutiveCount: 1,
                messages: messages,
                vitalDim: PO,
              },
              {
                alertType: THRESHOLD_READING,
                consecutiveCount: 1,
                trigger: ['L'],
                messages: messages,
                vitalDim: PO,
              },
              {
                alertType: THRESHOLD_READING,
                consecutiveCount: 1,
                trigger: ['LL'],
                messages: messages,
                vitalDim: PO,
              },
            ],
            patientInstructions:
              'Coronavirus disease 2019 (COVID-19) is an infectious disease caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2). Someone infected with COVID-19 and like other lung infections, level of oxygen saturation carried in red blood cells are reduced reflecting the reduced function of the lungs. The purpose of monitoring oxygen saturation level is to allow individuals feeling shortness of breadth or having cough monitor their condition and decide or to be decided by doctors if further actions is required. Please note standard thresholds had been preset to WHO guidelines where Green is normal. At Orange, you should assess if you possess other symptoms of COVID-19 and obtain professional medical advice. At Red, kindly seek immediate medical attention.',
          },
        ],
        devices: [{ skuId: 0, chargeItemCodeId: 0 }],
      },
    ];

    return {
      orgInfo: {
        type,
        parentId,
        name,
        logo,
        regNo,
        isTaxRegistered: false,
        taxRegistrationNumber: null,
        isRequirePONumber: false,
        poNumber: null,
        billingDate: 1,
        paymentTerm: 0,
        latePaymentDays: null,
        lateDeviceReturnDays: null,
        latePaymentInterest: null,
        refundDate: null,
        isInvoiceEnabled: false,
        isCovidOrg: true,
        pricePlan: pricePlan ? pricePlan.value : null,
        creditCardToken,
        isKioskOrg,
        sendMaintenanceNotification: true,
      },
      phone,
      address,
      primaryAdmin: {
        firstName: priFirstName,
        lastName: priLastName,
        email: priEmail,
        mobile: priMobile,
        home_office: priOffice,
      },
      ...(secFirstName && secLastName && secEmail && secMobile && secOffice
        ? {
            secondaryAdmin: {
              firstName: secFirstName,
              lastName: secLastName,
              email: secEmail,
              mobile: secMobile,
              home_office: secOffice,
            },
          }
        : {}),
      chargeItemCodes,
      adminPrograms,
    };
  },
  be2fe: R.identity,
};

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      organizations,
      pagination: { page, pageSize, total },
    },
  }) => ({
    list: R.map(({ name, secondaryAdmin, ...rest }) => ({
      ...rest,
      secondaryAdmin: secondaryAdmin || {},
      name,
      value: name,
      label: name,
    }))(organizations),
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const retrieveSortingFilter = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const retrieveAdminContactList = {
  fe2be: R.identity,
  be2fe: ({ value: { profiles: list, pagination} }) => ({ 
    list, 
    pagination
  }),
};

const assignToPrimaryContact = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const updateContact = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveOne = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveTypes = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveParents = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({
    list: R.map(
      ({ id, value, label, ...rest }) => ({
        ...rest,
        id,
        value: value.name,
        label,
        ...value,
      }),
      value,
    ),
  }),
};

const retrieveDepartmentsOf = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      organizations,
      pagination: { page, pageSize, total },
    },
  }) => ({
    list: R.map(({ name, secondaryAdmin, ...rest }) => ({
      ...rest,
      secondaryAdmin: secondaryAdmin || {},
      name,
      value: name,
      label: name,
    }))(organizations),
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const update = {
  fe2be: ({
    avatar,
    orgInfo: {
      orgType: { value: type },
      parentOrg: { options: parentOrgOptions, value: parentOrgValue } = {},
      orgName: name,
      compRegNo: regNo,
      compPhone: phone,
      compAddr1: line_1,
      compAddr2: line_2,
      postalCode,
      country,
      state: { value: state = null } = {},
      city: { value: city },
    },
    accounts = [], 
    billingInfo: {
      taxRegNo: taxRegistrationNumber,
      poNo: poNumber,
      billingDayth: { value: billingDate },
      isCovidOrg,
      isKioskOrg,
      paymentTerms,
      lateDeviceReturn,
      latePayment,
      paymentFees,
      refundDate: { value: refundDate },
      isInvoiceEnabled,
      isReportingEnabled,
    },
    addOnCovidOrganisation: {
      pricePlan = {},
    } = {},
    attributes: { isExtensionEnabled, isSuspensionEnabled, isContinuousMonitoringEnabled },
    others: { sendMaintenanceNotification = {} } = {},
  }) => {
    const parentId =
      type === 'GROUP'
        ? R.pipe(R.find(R.propEq('value', R.prop('value', country))), R.prop('id'))(R.prop('options', country))
        : R.pipe(R.find(R.propEq('value', parentOrgValue)), R.prop('id'))(parentOrgOptions);

    const isDataURL = (s) => {
      return !!s.match(isDataURL.regex);
    };
    isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

    const orgPayload = {
      parentOrg: parentId,
      logo: null,
      name,
      contacts: accounts,
      regNo,
      phone,
      address: [
        {
          type: 'BILLING',
          country: R.prop('value', country),
          state,
          city,
          line_1,
          line_2,
          postalCode,
        },
      ],
      isTaxRegistered: !!taxRegistrationNumber,
      taxRegistrationNumber,
      isRequirePONumber: !!poNumber,
      poNumber,
      billingDate,
      isCovidOrg,
      pricePlan: pricePlan ? pricePlan.value : null,
      paymentTerm: parseFloat(paymentTerms),
      isKioskOrg,
      latePaymentDays: parseFloat(latePayment),
      lateDeviceReturnDays: parseFloat(lateDeviceReturn),
      latePaymentInterest: parseFloat(paymentFees),
      refundDate,
      isInvoiceEnabled,
      isReportingEnabled,
      ...(type !== 'CLUSTER')
      ? {
          attributes: {
            isExtensionEnabled,
            isSuspensionEnabled,
            isContinuousMonitoringEnabled
          },
        }
      : {},
      sendMaintenanceNotification: type === 'GROUP' && sendMaintenanceNotification ? true : null,
    };

    if (isDataURL(avatar)) {
      orgPayload.logo = {
        file_suffix: '.jpeg',
        data: R.replace('data:image/jpeg;base64,', '', avatar),
      };
    }

    return orgPayload;
  },
  be2fe: R.identity,
};

const retrieveOrgInvoices = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      organizations,
      pagination: { page, pageSize, total },
    },
  }) => ({
    list: R.map(({ ...rest }) => ({ ...rest }))(organizations),
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const isOrganizationWithinPlan = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const upgradeCovidSubscriptionPlan = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getCovidSubscriptionPlan = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const cancelCovidSubscriptionPlan = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  create,
  createCovid,
  update,
  retrieveMany,
  retrieveTypes,
  retrieveParents,
  retrieveDepartmentsOf,
  retrieveOne,
  retrieveOrgInvoices,
  isOrganizationWithinPlan,
  upgradeCovidSubscriptionPlan,
  getCovidSubscriptionPlan,
  cancelCovidSubscriptionPlan,
  retrieveSortingFilter,
  retrieveAdminContactList,
  assignToPrimaryContact,
  updateContact
};
