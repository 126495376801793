import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const createNotes = async ({ content: { data } }) =>
  R.pipe(adapter.notes.createNotes.fe2be, httpPost('note/create'), (response) =>
    response.then(adapter.notes.createNotes.be2fe),
  )(data);

const closePastAlert = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ id }) => httpGet(`alertSummary/${id}/close`),
    (response) => response.then(adapter.notes.closePastAlert.be2fe),
  )(ids);

const closePaused = async ({ content, params: { ids } }) => {
  const url = R.pipe(getIdsObject, ({ id }) => `pauseSummary/${id}/close`)(ids);

  return R.pipe(adapter.notes.closePaused.fe2be, httpPut(url), (response) =>
    response.then(adapter.notes.closePaused.be2fe),
  )(content);
};

const closeAlert = async ({ content: { data } }) =>
  R.pipe(adapter.notes.createNotes.fe2be, httpPost('alerts/close'), (response) =>
    response.then(adapter.notes.closeAlert.be2fe),
  )(data);

const retrieveNotes = async ({ content: { data } }) =>
  R.pipe(adapter.notes.createNotes.fe2be, httpPost('note/list'), (response) =>
    response.then(adapter.notes.retrieveNotes.be2fe),
  )(data);

const markNoteAsError = async ({ content: { data } }) =>
  R.pipe(adapter.notes.markNoteAsError.fe2be, httpPost('note/markNoteAsError'), (response) =>
    response.then(adapter.notes.markNoteAsError.be2fe),
  )(data);

const DM = {
  createNotes,
  closePastAlert,
  closePaused,
  closeAlert,
  retrieveNotes,
  markNoteAsError,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
