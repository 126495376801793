import * as R from 'ramda';

const saveProblemHistory = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const getProblemHistory = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

export default {
  saveProblemHistory,
  getProblemHistory,
};