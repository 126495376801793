import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const fetchReporting = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`reports/provider/patient/modulesAndFilters${queriesString}`),
    (response) => response.then(adapter.report.fetchReporting.be2fe),
  )(queries);

const fetchReportPatinetInfo = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId }) => httpGet(`reports/patient/${patientId}`),
    (response) => response.then(adapter.report.fetchReportPatinetInfo.be2fe),
  )(ids);

const fetchReportIndividualEnrollProgram = async ({ params: { ids, queries } }) => {
  const url = R.pipe(getIdsObject, ({ patientId }) => `reports/patient/${patientId}/enrolledPrograms`)(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.fetchReportIndividualEnrollProgram.be2fe),
  )(queries);
};

const getAlertsSummary = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/alerts/thresholdSummary`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getAlertsSummary.be2fe),
  )(queries);
};

const getNumberOfAlertsPerProgramme = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/alerts/thresholdSummary/enrolledPrograms/count`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getNumberOfAlertsPerProgramme.be2fe),
  )(queries);
};

const getAlertsDataChart = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/alerts/thresholdSummary/chart`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getAlertsDataChart.be2fe),
  )(queries);
};

const getCareTaskAdherenceSummary = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/careTask/adherence/summary`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getCareTaskAdherenceSummary.be2fe),
  )(queries);
};

const getCareTaskListProgrammes = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/careTask/adherence/programs/stats`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getCareTaskListProgrammes.be2fe),
  )(queries);
};

const getCareTaskDataChart = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/careTask/adherence/chart`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getCareTaskDataChart.be2fe),
  )(queries);
};

const getVitalsSummary = async ({ params: { ids, queries } }) => {
  const url = R.pipe(getIdsObject, ({ patientId }) => `reports/provider/patient/${patientId}/vitals/summary`)(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getVitalsSummary.be2fe),
  )(queries);
};

const getVitalDataChart = async ({ params: { ids, queries } }) => {
  const url = R.pipe(getIdsObject, ({ patientId }) => `reports/provider/patient/${patientId}/vitals/chart`)(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getVitalDataChart.be2fe),
  )(queries);
};

const getVitalsPerProgramme = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}/vitals/programs/stats`,
  )(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.report.getVitalsPerProgramme.be2fe),
  )(queries);
};

const getLatestOTT = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ enrolledProgram }) => httpGet(`reports/enrolledProgram/${enrolledProgram}/ott/latest`),
    (response) => response.then(adapter.report.getLatestOTT.be2fe),
  )(ids);

const createIndividualReporting = async ({ content }) =>
  R.pipe(adapter.report.createIndividualReporting.fe2be, httpPost('reports'), (response) =>
    response.then(adapter.report.createIndividualReporting.be2fe),
  )(content);

const retrieveReports = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ reportId }) => httpGet(`reports/${reportId}`),
    (response) => response.then(adapter.report.retrieveReports.be2fe),
  )(ids);

const updateReport = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.report.updateReport.fe2be,
    R.pipe(getIdsObject, ({ reportId }) => httpPut(`reports/${reportId}`))(ids),
    (response) => response.then(adapter.report.updateReport.be2fe),
  )(content);

const fetchIndividualReportTemplate = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ reportId }) => httpGet(`reports/provider/patient/templates/${reportId}`),
    (response) => response.then(adapter.report.fetchIndividualReportTemplate.be2fe),
  )(ids);

const fetchReportingTemplate = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`reports/provider/patient/templates${queriesString}`),
    (response) => response.then(adapter.report.fetchReportingTemplate.be2fe),
  )(queries);

const fetchAllParticipantList = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`reports/patient/${queriesString}`),
    (response) => response.then(adapter.report.fetchAllParticipantList.be2fe),
  )(queries);

const fetchOverallParticipantEP = async ({ content }) =>
  R.pipe(
    adapter.report.fetchOverallParticipantEP.fe2be,
    httpPost('reports/patient/overall/enrolledPrograms'),
    (response) => response.then(adapter.report.fetchOverallParticipantEP.be2fe),
  )(content);

const getAllParticipantVitalSummary = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantVitalSummary.fe2be,
    httpPost('reports/provider/patient/overall/vitals/summary'),
    (response) => response.then(adapter.report.getAllParticipantVitalSummary.be2fe),
  )(content);

const getAllParticipantChart = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantChart.fe2be,
    httpPost('reports/provider/patient/overall/vitals/chart'),
    (response) => response.then(adapter.report.getAllParticipantChart.be2fe),
  )(content);

const getAllParticipantVitalData = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantVitalData.fe2be,
    httpPost('reports/provider/patient/overall/vitals/programs/stats'),
    (response) => response.then(adapter.report.getAllParticipantVitalData.be2fe),
  )(content);

const getAllParticipantCareTaskSummary = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantCareTaskSummary.fe2be,
    httpPost('reports/provider/patient/overall/careTask/adherence/summary'),
    (response) => response.then(adapter.report.getAllParticipantCareTaskSummary.be2fe),
  )(content);

const getAllParticipantCareTaskChart = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantCareTaskChart.fe2be,
    httpPost('reports/provider/patient/overall/careTask/adherence/chart'),
    (response) => response.then(adapter.report.getAllParticipantCareTaskChart.be2fe),
  )(content);

const getAllParticipantCareTaskData = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantCareTaskData.fe2be,
    httpPost('reports/provider/patient/overall/careTask/adherence/programs/stats'),
    (response) => response.then(adapter.report.getAllParticipantCareTaskData.be2fe),
  )(content);

const getAllParticipantAlertSummary = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantAlertSummary.fe2be,
    httpPost('reports/provider/patient/overall/alerts/thresholdSummary'),
    (response) => response.then(adapter.report.getAllParticipantAlertSummary.be2fe),
  )(content);

const getAllParticipantAlertChart = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParticipantAlertChart.fe2be,
    httpPost('reports/provider/patient/overall/alerts/thresholdSummary/chart'),
    (response) => response.then(adapter.report.getAllParticipantAlertChart.be2fe),
  )(content);

const getAllParicipantAlertData = async ({ content }) =>
  R.pipe(
    adapter.report.getAllParicipantAlertData.fe2be,
    httpPost('reports/provider/patient/overall/alerts/thresholdSummary/enrolledPrograms/count'),
    (response) => response.then(adapter.report.getAllParicipantAlertData.be2fe),
  )(content);

const retrieveDailyParticipantsAlertsSummary = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`alertSummary${queriesString}`),
    (response) => response.then(adapter.report.retrieveDailyParticipantsAlertsSummary.be2fe),
  )(queries);

const retrieveDailyParticipantsPausedSummary = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`pauseSummary${queriesString}`),
    (response) => response.then(adapter.report.retrieveDailyParticipantsPausedSummary.be2fe),
  )(queries);

const DM = {
  fetchReporting,
  fetchReportPatinetInfo,
  fetchReportIndividualEnrollProgram,
  getAlertsSummary,
  getNumberOfAlertsPerProgramme,
  getAlertsDataChart,
  getCareTaskAdherenceSummary,
  getCareTaskListProgrammes,
  getCareTaskDataChart,
  getVitalsSummary,
  getVitalDataChart,
  getVitalsPerProgramme,
  getLatestOTT,
  createIndividualReporting,
  retrieveReports,
  updateReport,
  fetchIndividualReportTemplate,
  fetchReportingTemplate,
  fetchAllParticipantList,
  fetchOverallParticipantEP,
  getAllParticipantVitalSummary,
  getAllParticipantChart,
  getAllParticipantVitalData,
  getAllParticipantCareTaskSummary,
  getAllParticipantCareTaskChart,
  getAllParticipantCareTaskData,
  getAllParticipantAlertSummary,
  getAllParticipantAlertChart,
  getAllParicipantAlertData,
  retrieveDailyParticipantsAlertsSummary,
  retrieveDailyParticipantsPausedSummary,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
