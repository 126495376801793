import essentials from './essentials';
import configs from './configs';
import config from './config';
import policies from './policies';
import provider from './provider';
import careteams from './careteams';
import patient from './patient';
import program from './program';
import programs from './programs';
import measurement from './measurement';
import note from './note';
import notes from './notes';
import task from './task';
import alert from './alert';
import chat from './chat';
import sku from './sku';
import skuLowStockThreshold from './skuLowStockThreshold';
import device from './device';
import consumables from './consumables';
import users from './users';
import consumableSales from './consumableSales';
import orgs from './orgs';
import countries from './countries';
import nationalities from './nationalities';
import genders from './genders';
import chargeitemcodes from './chargeitemcodes';
import audittrail from './audittrail';
import billing from './billing';
import inventory from './inventory';
import patients from './patients';
import versions from './versions';
import changeRequest from './changeRequest';
import contractNo from './contractNo';
import contracts from './contracts';
import devices from './devices';
import currencies from './currencies';
import feedbacks from './feedbacks';
import feedbackResponse from './feedbackResponse';
import taskObservation from './taskObservation';
import reporting from './reporting';
import report from './report';
import problems from './problems';
import adt from './adt';
import adtSurvey from './adtSurvey';
import adherenceReport from './adherenceReport';

export default {
    essentials,
    configs,
    config,
    policies,
    provider,
    careteams,
    patient,
    program,
    programs,
    measurement,
    note,
    notes,
    task,
    alert,
    chat,
    sku,
    skuLowStockThreshold,
    device,
    consumables,
    users,
    consumableSales,
    orgs,
    countries,
    nationalities,
    genders,
    chargeitemcodes,
    audittrail,
    billing,
    inventory,
    patients,
    versions,
    changeRequest,
    contractNo,
    contracts,
    devices,
    currencies,
    feedbacks,
    feedbackResponse,
    taskObservation,
    reporting,
    report,
    problems,
    adt,
    adtSurvey,
    adherenceReport
};